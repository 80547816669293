<template>
  <div class="mt-3">
    <!-- 标题 -->
    <div class="intro-y flex items-center h-10">
      <h2 class="text-lg font-medium truncate mr-5">用户基本信息</h2>
    </div>

    <!-- 表单 -->
    <div class="box p-5 intro-x mt-2" style="min-width: 300px;">

      <div class="pb-5 preview">

        <!-- 用户名称 -->
        <div  >
          <label>用户名称</label>
          <input v-model="formData.username" type="email" placeholder="请输入用户名称" class="input w-full rounded-full border mt-2">
        </div>
        <!-- 联系人姓名 -->
        <div class="mt-5">
          <label>联系人姓名</label>
          <input v-model="formData.contact_name" type="email" placeholder="请输入联系人姓名" class="input w-full rounded-full border mt-2">
        </div>

        <!-- 用户id -->
        <div class="mt-5">
          <label>用户id</label>
          <input v-model="formData.zhios_id" disabled type="email" placeholder="请输入用户id" class="input w-full rounded-full border mt-2">
        </div>

        <!-- 联系人手机号 -->
        <div class="mt-5">
          <label>联系人手机号</label>
          <input v-model="formData.mobile" type="email" placeholder="请输入联系人手机号" class="input w-full rounded-full border mt-2">
        </div>

      </div>
      <div class="border-t border-gray-200 pt-5 flex" style="justify-content: end;">
        <button class="button w-24 mr-1 mb-2 border text-gray-700" @click="resetForm()">取消</button>
        <button class="button w-24 mr-1 mb-2 bg-theme-1 text-white" @click="submitForm()">确认</button>
      </div>
    </div>

  </div>
</template>

<script>
import { fetchData, saveData } from '@/api/lm-service-provider-info/lm-service-provider-info'

/**
 * 服务商信息
 */
export default {
  components: {},
  data() {
    return {
      // 提交表单
      formData: {
      
   
        // 联系人姓名
        contact_name: '',
        // 联系人姓名
        username: '',
        // 联系人手机号
        mobile: ''
      }
    }
  },
  created() {
    this._fetchData()
  },
  methods: {
    // 提交表单
    submitForm() {
      this.$confirm('确定修改？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this._saveData()
      }).catch(() => {
        this.$message.info('取消')
      })
    },
    // 重置表单
    resetForm() {
      this._fetchData(true)
    },
    // 保存数据
    _saveData() {
      const params = this.formData
      saveData(params).then(resp => {
        if (resp.code === 0) {
          this.$message.success('保存成功～')
          this._fetchData()
        } else {
          this.$message.error(resp.msg)
        }
      })
    },
    // 初始化数据
    _fetchData(needToast = false) {
      fetchData().then(resp => {
   
        if (resp.code === 0) {
          if (needToast) this.$message.success('刷新成功～')
          if (resp.data) this.formData = resp.data
        } else {
          this.$message.error(resp.msg)
        }
        // console.log(JSON.stringify(resp))
      }).catch((err) => this.$message.success(err))
    }
  }
}
</script>

<style scoped>

</style>