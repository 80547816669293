/**
 * ==================================================================
 * ======================= 商户管理 ===================
 * ==================================================================
 */
import request from '@/utils/request'

/**
 * 服务商管理页数据
 */
export function fetchData() {
    return request({
        url: '/user/User/getUserInfo',
        method: 'get'
    })
}

/**
 * 服务商模式保存
 */
export function saveData(params) {
    return request({
        url: '/user/User/saveUserInfo',
        method: 'post',
        data: params
    })
}